exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-hire-us-tsx": () => import("./../../../src/pages/hireUs.tsx" /* webpackChunkName: "component---src-pages-hire-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-2023-02-20-ui-ux-case-study-redesigning-linkedin-jobs-index-mdx": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/vercel/path0/src/content/2023-02-20-ui-ux-case-study-redesigning-linkedin-jobs/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-2023-02-20-ui-ux-case-study-redesigning-linkedin-jobs-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-2023-12-29-information-architecture-ia-in-ux-index-mdx": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/vercel/path0/src/content/2023-12-29-information-architecture-ia-in-ux/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-2023-12-29-information-architecture-ia-in-ux-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-2024-02-18-01-00-00-healthy-friction-in-ux-index-mdx": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/vercel/path0/src/content/2024-02-18-01-00-00-healthy-friction-in-ux/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-2024-02-18-01-00-00-healthy-friction-in-ux-index-mdx" */)
}

